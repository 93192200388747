@use "./mixins" as *;

@include normalize;

* {
  box-sizing: border-box;
  .win {
    padding: 0 1em;
    background: linear-gradient(-45deg, #ff9d64, #f5620c);
    background-size: 400% 400%;
    animation: gradient 2s ease infinite;
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
  
    50% {
      background-position: 100% 50%;
    }
  
    100% {
      background-position: 0% 50%;
    }
  }
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 150%;
  background-color: black;
  background-image: url("../../assets/img/bg.png");
  background-size: 100%;
  background-repeat: repeat;
  text-align: center;
  color:  #28bdbd;
  .logo {
    width: 70%;
    max-width: 484px;
  }
  
  h1 {
    width: 100%;
    padding-top: 1em;
    margin: 0;
    font-family: "Unlock", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 44px;
    text-align: center;
  }

}

